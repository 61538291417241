<script>
import store from '@/store'

export default {
    data() {
        return {
            boxId: '',
            boxIdWatcher: null
        }
    },
    methods: {
        watchBoxId() {
            this.boxIdWatcher = store.watch(
                (state, getters) => getters.targetBoxId,
                (val) => {
                    console.log('boxId val: ' + val)
                    if (val) this.boxId = val
                }
            )
        }
    },
    activated() {
        this.watchBoxId()
    },
    deactivated() {
        // unwatch box id
        if (this.boxIdWatcher) this.boxIdWatcher()
    }
}
</script>
