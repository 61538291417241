<template>
    <v-card flat :height="height">
        <div class="text-xs-center">
            <template v-if="isCameraMode">
                <p class="text-xs-center subheading">{{ $t('QRReader.scan-QR-cabinet') }}</p>
                <qrcode-stream @decode="onDecode" @init="onInit" :camera="camera">
                    <p v-if="loading" class="text-xs-center subheading">{{ $t('QRReader.turn-on-camera') }}</p>
                </qrcode-stream>
            </template>
            <template v-else>
                <p class="text-xs-center subheading ma-0">
                    <template v-if="isCameraOK">{{ $t('QRReader.read-QR-with-scanner') }}</template>
                    <template v-else>{{error}}<br>{{ $t('QRReader.read-QR-code') }}</template>
                </p>
                <BoxQRCode :content="qrContent"></BoxQRCode>
                <p>
                    {{ $t('takeout.wait-a-while') }}{{ $t('takeout.box-screen') }}<v-icon color="green">check</v-icon>{{ $t('takeout.no-response-press-button') }}
                    <v-btn fluid class="pa-0" fab dark color="green" @click="getBoxInformation"><v-icon>search</v-icon></v-btn>
                </p>
                <!-- <p>若無回應，請回首頁再操作一次</p> -->
            </template>
        </div>
        <v-bottom-nav absolute v-if="isCameraOK" :active.sync="mode" :value="true" color="grey lighten-3">
            <v-btn flat color="cyan darken-3" value="camera"><span class="subheading">{{ $t('QRReader.camera') }}</span></v-btn>
            <v-btn flat color="cyan darken-3" value="qrcode"><span class="subheading">QR Code</span></v-btn>
        </v-bottom-nav>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <v-dialog persistent v-model="emptyDialog" width="250">
            <v-card class="text-xs-center">
                <v-card-text  class="title">
                    {{ warnMsg }}
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn block class="subheading" color="primary" @click="close()"> {{ $t('QRReader.close') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { HostUrl } from '@/store'
import { qrcodeRoute } from '@/store'
import BoxQRCode from '@/components/BoxQRCode'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { parseBoxQR } from '@/utils/AuthService'
import { QrcodeStream } from 'vue-qrcode-reader'
import { getAxiosConfig } from '@/utils/AuthService'
import { handleSelectBox } from '@/utils/MQTTClient'
import i18n from '@/i18n'

const Mode = {
    CAMERA: 'camera',
    QRCODE: 'qrcode'
}

export default {
    name: 'QRReader',
    components: { BoxQRCode, QrcodeStream, WaitingDialog },
    mixins: [ WaitDialogControl ],
    props: {
        camera: {
            type: String,
            default: 'auto'
        }
    },
    data() {
        return {
            mode: Mode.CAMERA,
            loading: false,
            error: null,
            qrContent: { action: 'auth' },
            warnMsg: null,
            emptyDialog: false
        }
    },
    computed: {
        isCameraOK() {
            return this.error === null
        },
        isCameraMode() {
            return this.mode === Mode.CAMERA
        },
        height() {
            switch(this.mode) {
                case Mode.CAMERA: return '450px'
                case Mode.QRCODE: return '380px'
                default: return '300px'
            }
        },
        color() {
            switch(this.mode) {
                case Mode.CAMERA: return 'success'
                case Mode.QRCODE: return 'info'
                default: return 'white'
            }
        }
    },
    methods: {
        parseQR: function(value) {
            if (store.getters.user.isGuest && qrcodeRoute(value))  return
            else {
                try {
                    let query = parseBoxQR(value)
                    this.$emit('query', query)
                } catch (error) {
                    console.log(error)
                    store.commit('setSnackBar', {
                        message: i18n.t('QRReader.invalid-QR-code'),
                        color: 'warning'
                    })
                }
            }
        },
        onDecode (result) {
            this.parseQR(result);
        },
        async onInit (promise) {
            this.warnMsg = null
            this.emptyDialog = null
            this.loading = true
            this.error = null
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "無使用相機權限"
                } else if (error.name === 'NotFoundError') {
                    this.error = "本裝置無相機可使用"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "僅允許 HTTPS 或 localhost 使用"
                } else if (error.name === 'NotReadableError') {
                    this.error = "其他程序正在使用相機?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "無法控制本裝置相機"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    const errorMessage = i18n.t('QRReader.browser-cannot-open-camera')
                    console.log(errorMessage)
                    this.error = errorMessage
                } else {
                    this.error = i18n.t('QRReader.unable-use-camera')
                }
                this.mode = Mode.QRCODE
            } finally {
                this.loading = false
            }
        },
        async getBoxInformation() {
            this.warnMsg = null
            this.showDialog(i18n.t('takeout.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            let url = `${HostUrl}/api/user/token/checkboxid`
            let payload = store.getters.userInfo
            try {
                let response = await axios.post(url, payload, getAxiosConfig())
                if (response.data.code == 0) {
                    handleSelectBox(response.data)
                } else throw new Error(response.data.message)
            } catch (error) {
                if (error.message === "請掃描 QR code") {
                    error.message = i18n.t('takeout.scan-QR-code')
                }
                this.emptyDialog = true
                this.warnMsg = error.message
            } finally {
                this.closeDialog()
            }
        },
        close() {
            this.emptyDialog = null
            this.warnMsg = null
        }
    }
}
</script>

<style scoped>
</style>
